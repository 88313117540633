import MDRouter from "@/service/router";
import openCardlist from "@/components/order_detail/openCardlist";
import { mapState, useStore } from "vuex";
import topay from "@/components/order_detail/topay";
import { env } from "md-base-tools/env";
import orderRemark from "@/components/order_detail/orderRemark";
import orderTel from "@/components/order_detail/orderTel";
import DialogBtn from "@/components/order_detail/dialogBtn";
import detailsHeader from "@@/order_detail/detailsHeader.vue";
import detailsAddress from "@@/order_detail/detailsHeaderAddress.vue";
import detailsContent from "@@/order_detail/detailsContent.vue";
import detailsStatus from "@@/order_detail/detailsStatus.vue";
import lowerdev from "@/service/lowerdev";
import { useRoute } from "vue-router";
import orderRefund from "@/components/order_detail/orderRefund";
import ClipboardJS from "clipboard";
// eslint-disable-next-line no-unused-vars
import { postOrder, get, post } from "@/utils/apiBase.js";
import domain from "@/service/domain";
// eslint-disable-next-line no-unused-vars
import { showConfirmDialog, showSuccessToast, showToast } from "vant";
import { getMallOrderDetail, getPayMethodList } from "@api/index";
import DetailsProductContent from "@/components/order_detail/detailsProductContent.vue";
import paypay from '@/utils/paypay.js';
export default {
  data() {
    return {
      env: env,
      isMobile: env.isMobile(),
      pay_id: "",
      copyboard: null,
      dialog_show: false,
      remarkShow: false,
      payShow: false,
      openCardlistMod: false,
      orderRefundShow: false,
      miniProgram: false,
      giftWrapShow: false,
      telShow: false,
      dialog_address: false,
      orderType: "shop",
      html: "",
      dialog_btn: [],
      addressBtn: [{
        name: "去地址簿选地址",
        title: "去地址簿选地址",
        type: 1
      }, {
        name: "编辑当前订单地址",
        title: "编辑当前订单地址",
        type: 0
      }],
      payPay: null,
      pay_resource: '',
      // 支付方式
      methodsList: [] // 支付方式数组
    };
  },
  components: {
    detailsStatus,
    // eslint-disable-next-line vue/no-unused-components
    detailsHeader,
    // eslint-disable-next-line vue/no-unused-components
    detailsContent,
    DialogBtn,
    orderTel,
    orderRemark,
    topay,
    orderRefund,
    detailsAddress,
    // eslint-disable-next-line vue/no-unused-components
    DetailsProductContent,
    openCardlist
  },
  async setup() {
    const route = useRoute();
    let orderId = route.query.order_id || "";
    const store = useStore();
    const res = await getMallOrderDetail({
      order_id: orderId
    });
    if (res.status !== 0) return;
    const orderInfo = res.data;
    orderId = orderInfo.order_id;
    // orderInfo.shop.skus[0].sku_btns=[{title:'申请退款'}]
    store.commit("setValue", {
      obj: "info",
      value: orderInfo
    });
    return {
      orderId,
      orderInfo
    };
  },
  watch: {},
  beforeMount() {
    // 初始化支付工具
    this.payPay = new paypay({
      success: this.PaySuccessBack,
      type: 'mall'
    });
  },
  mounted() {
    if (!this.if_no_header) {
      document.querySelectorAll(".details-box")[0].setAttribute("class", "details-box active viewBox");
    }
    document.body.style.background = "#f2f2f2";
    // eslint-disable-next-line no-undef
    userHeader.backnavOnShow(false);
    // eslint-disable-next-line no-undef
    userHeader.titleText("订单详情");
    if (sessionStorage.getItem("miniProgram") === "weixin") {
      lowerdev.dynamicScript("https://res.wx.qq.com/open/js/jweixin-1.4.0.js");
    }
    var target = this.$refs.copy;
    this.copyboard = new ClipboardJS(target, {
      text(target) {
        return target.getAttribute("copyData");
      }
    });
    this.copyboard.on("success", () => {
      // eslint-disable-next-line
      showSuccessToast("复制成功");
    });
  },
  beforeUnmount() {
    document.body.style.background = "#f8f8f8";
  },
  computed: {
    ...mapState({
      userId: state => state.user.userId
    }),
    if_show() {
      return !(this.env.isWeibo() || this.env.isWeixin() || this.env.isModianIos() || this.env.isModianAndroid());
    },
    if_no_header() {
      return !(this.isMobile || this.env.isModianIos() || this.env.isModianAndroid()) || this.env.isWeibo();
    }
  },
  methods: {
    openboxmore() {
      this.openCardlistMod = true;
    },
    close() {
      // this[str] = false
      showToast({
        type: "text",
        message: "操作成功",
        onClose: () => {
          window.location.reload();
        }
      });
    },
    lookTeamfund() {
      location.href = domain.wap + "/teamfund/" + this.orderInfo.teamfund_id + ".html";
    },
    op(type) {
      const that = this;
      // eslint-disable-next-line eqeqeq
      const orderId = this.orderId;
      // eslint-disable-next-line no-case-declarations
      switch (type) {
        case "subscribe_records":
          // 查看记录
          // eslint-disable-next-line eqeqeq
          location.href = `${domain.wap}/user_order/subscribe_record/${this.orderInfo.product_info.id}`;
          break;
        case "del_order":
          // 确认删除
          showConfirmDialog({
            message: "是否确认要删除？"
          }).then(() => {
            let searchParams = {
              url: "/mall/order/delete",
              method: "post",
              props: {
                order_id: orderId
              }
            };
            postOrder(searchParams.url, searchParams.props).then(res => {
              if (res) {
                showToast({
                  type: "text",
                  message: "删除成功",
                  onClose: () => {
                    history.back();
                  }
                });
              }
            });
          });
          break;
        case "cancel_order":
          // 确认取消订单
          showConfirmDialog({
            message: "是否确认要取消？"
          }).then(() => {
            let searchParams = {
              url: "/mall/order/cancel",
              method: "post",
              props: {
                order_id: orderId
              }
            };
            postOrder(searchParams.url, searchParams.props).then(res => {
              if (res) {
                showToast({
                  type: "text",
                  message: "取消成功",
                  onClose: () => {
                    window.location.reload();
                  }
                });
              }
            });
          });
          break;
        case "update_address":
          // 确认修改地址
          // this.dialog_address = true
          // window.location.href =
          // '/user_order/update_order_address_shop/' + this.orderInfo.order_id
          this.ifAddress();
          break;
        case "update_remark":
          // 确认修改备注
          // lowerdev.confirm({ msg: '是否确认要修改备注？' }, () => {
          //   window.location.href = `${domain.wap}/user_order/update_order_des/${orderId}`
          // })
          this.remarkShow = true;
          break;
        case "buy_again":
          // webview  点击后跳转至商品详情页
          if (this.orderInfo.extract_card_type && this.orderInfo.extract_card_type > 0) {
            MDRouter.goLuckycardsInfo(this.orderInfo.extract_card_id);
            return false;
          }
          MDRouter.goMallInfo(this.orderInfo.shop.shop_id);
          break;
        case "update_mobile":
          // 确认修改手机号  --> 需要先获取收货地址
          this.telShow = true;
          break;
        case "create_tail":
          if (this.orderInfo.shop.skus && this.orderInfo.shop.skus[0].sku_id) {
            const arr = this.orderInfo.shop.skus.map(e => {
              return String(e.sku_id);
            });
            const obj = {
              presale_pay_type: 2,
              first_order_id: String(orderId),
              sku_id: arr
            };
            location.href = `${domain.wap}/product/payment?order_final_arg=${JSON.stringify(obj)}`;
          }
          break;
        case "pay":
          // 去支付
          if (this.orderInfo.extract_card_type && this.orderInfo.extract_card_type > 0) {
            const luckycardsData = {
              apply_pay_amount: this.orderInfo.amount,
              order_id: this.orderInfo.order_id
            };
            sessionStorage.setItem("luckycardsData", JSON.stringify(luckycardsData));
            MDRouter.goLuckycardsInfo(this.orderInfo.extract_card_id, {
              luckycardsData: JSON.stringify(luckycardsData)
            });
            return false;
          }
          this.toPay();
          // if (this.orderInfo.not_support_wx) {
          //   if (sessionStorage.getItem("miniProgram") === "weixin") {
          //     // eslint-disable-next-line no-undef
          //     wx.miniProgram.navigateTo({
          //       url:
          //         "/pages/commonPages/contact/contact?order_id=" +
          //         this.orderInfo.order_id +
          //         "&path=mall_order_pay",
          //     });
          //   } else {
          //     showToast("因微信支付限制，请移驾到APP内下单支付");
          //   }
          //   return false;
          // }
          // if (this.env.isWeixin()) {
          //   let searchParams = {
          //     url: "/mall/order/resetpay",
          //     method: "post",
          //     props: {
          //       order_id: orderId,
          //       pay_resource: "weixin",
          //     },
          //   };
          //   postOrder(searchParams.url, searchParams.props).then((res) => {
          //     if (res) {
          //       this.pay_id = res.data.pay_id;
          //       if (sessionStorage.getItem("miniProgram") === "weixin") {
          //         // eslint-disable-next-line no-undef
          //         wx.miniProgram.navigateTo({
          //           url:
          //             "/pages/payPages/pay_h5/pay_h5?pay_id=" + res.data.pay_id,
          //         });
          //       } else {
          //         var payParams = {
          //           url: "/mall/order/sendpay",
          //           method: "post",
          //           props: {
          //             pay_id: res.data.pay_id,
          //             pay_resource: "weixin",
          //             open_id: "",
          //           },
          //         };
          //         postOrder(payParams.url, payParams.props).then((res) => {
          //           if (res) {
          //             const json = {
          //               appId: res.data.data.appid || res.data.data.appId,
          //               timeStamp:
          //                 res.data.data.timestamp || res.data.data.timeStamp,
          //               nonceStr:
          //                 res.data.data.noncestr || res.data.data.nonceStr,
          //               package: res.data.data.package || res.data.data.packAge,
          //               signType:
          //                 res.data.data.signtype || res.data.data.signType,
          //               paySign: res.data.data.paysign || res.data.data.paySign,
          //             };
          //             this.callWeixinPay(json);
          //           } else {
          //             showToast(res.message);
          //           }
          //         });
          //       }
          //     } else {
          //       showToast("支付失败");
          //     }
          //   });
          // } else if (this.env.isWeibo()) {
          //   let searchParams = {
          //     url: "/mall/order/resetpay",
          //     method: "post",
          //     props: {
          //       order_id: orderId,
          //       pay_resource: "weibo",
          //     },
          //   };
          //   postOrder(searchParams.url, searchParams.props).then((res) => {
          //     if (res) {
          //       this.pay_id = res.pay_id;
          //       var payParams = {
          //         url: "/mall/order/sendpay",
          //         method: "post",
          //         props: {
          //           pay_id: res.data.pay_id,
          //           pay_resource: "weibo",
          //           open_id: "",
          //         },
          //       };
          //       postOrder(payParams.url, payParams.props).then((res) => {
          //         if (res) {
          //           window.location.href = res.data.data;
          //         } else {
          //           showToast("支付失败");
          //         }
          //       });
          //     } else {
          //       showToast("支付失败");
          //     }
          //   });
          break;
        case "delay":
          // 确认延长收货
          if (this.orderInfo.deliver_7 === 0) {
            showToast("系统自动签收前3天才可延长发货");
          } else {
            let searchParams = {
              url: "/mall/order/goods/delay",
              method: "post",
              props: {
                order_id: orderId
              }
            };
            postOrder(searchParams.url, searchParams.props).then(res => {
              if (res) {
                showToast("操作成功");
                this.$router.go(0);
              }
            });
          }
          break;
        case "cancel_order_refund":
          // 撤销退款
          showConfirmDialog({
            message: "确定要撤销退款吗？"
          }).then(() => {
            let searchParams = {
              url: "/mall/order/goods/confirm",
              method: "post",
              props: {
                refund_id: that.orderInfo.refund_id
              }
            };
            if (that.orderInfo.order_type !== 4) {
              searchParams.url = "/apis/mdorder/refund/cancelled";
            }
            postOrder(searchParams.url, searchParams.props).then(res => {
              if (res) {
                showToast({
                  type: "text",
                  message: that.orderInfo.order_type !== 4 ? res.message : res.sys_msg,
                  onClose: () => {}
                });
                this.$router.go(0);
              }
            });
            // on confirm
          }).catch(() => {
            // on cancel
          });
          break;
        case "appeal":
          // 电商申诉
          // this.gotoDetails({ item: that.orderInfo });
          // lowerdev.confirm(
          //   {
          //     msg: '确定要进行申诉吗？'
          //   },
          //   () => {
          //     let searchParams = {
          //       url: '/mall/order/refund/appeal',
          //       method: 'post',
          //       props: {
          //         refund_id: that.orderInfo.refund_id
          //       }
          //     }
          //     if (that.orderInfo.order_type !== 4) {
          //       searchParams.url = '/apis/mdorder/refund/appeal_apply'
          //     }
          //     postOrder(searchParams.url, searchParams.props).then((res) => {
          //       if (res) {
          //         showToast({
          //           type: 'text',
          //           message:
          //             that.orderInfo.order_type !== 4
          //               ? res.message
          //               : res.sys_msg,
          //           onClose: () => {}
          //         })
          //         this.$router.go(0)
          //       }
          //     })
          //   }
          // )
          break;
        case "confirm":
          // 确认-确认收货
          showConfirmDialog({
            message: "请您确认已收到全部商品后再点击“确认收货”，一旦确认将无法撤回。"
          }).then(() => {
            let searchParams = {
              url: "/mall/order/goods/confirm",
              method: "post",
              props: {
                order_id: orderId
              }
            };
            postOrder(searchParams.url, searchParams.props).then(res => {
              console.log(res);
              if (res) {
                showToast({
                  type: "text",
                  message: that.orderInfo.order_type !== 4 ? res.message : res.sys_msg,
                  onClose: () => {}
                });
                that.giftWrapShow = true;
              }
            });
          });
          break;
        case "order_comment_add":
          // 发表评价
          this.orderDetailCallback(() => {
            location.href = `${domain.wap}/product/evaluate/initiate?state=1&order_id=${orderId}&product_id=${this.orderInfo.shop.skus[0].product_id}&sku_id=${this.orderInfo.shop.skus[0].sku_id}`;
          });
          break;
        case "order_comment_edit":
          // 商城订单专属  -->  修改评论
          // 编辑评价
          window.location.href = `${domain.wap}/order_rate/update_rate?back_id=${orderId}&type=shop`;
          break;
        case "order_comment_detail":
          // 查看评论
          this.orderDetailCallback(() => {
            location.href = `${domain.wap}/product/evaluate?order_id=${orderId}&product_id=${this.orderInfo.shop.skus[0].product_id}&sku_id=${this.orderInfo.shop.skus[0].sku_id}`;
          });
          break;
        case "apply_after":
          // 商城订单专属 --> 申请售后
          location.href = domain.wap + "/product/refund/apply?soid=" + this.orderInfo.order_id;
          lowerdev.openUrl("refund/apply", {
            soid: this.orderInfo.order_id
          });
          break;
        case "look_logistics":
          // webview 商城订单专属  --> 查看物流
          location.href = domain.wap + "/product/logistics?order_id=" + this.orderInfo.order_id;
          break;
        case "look_goods":
          // webview 商城订单专属  --> 查看发货
          location.href = domain.wap + "/product/logistics?order_id=" + this.orderInfo.order_id;
          break;
        case "apply_refund":
          // 申请退款 webview
          this.orderRefundShow = true;
          break;
        case "reminder":
          // 商城订单专属  -->  催单
          if (this.orderInfo.pay_success_time) {
            // eslint-disable-next-line camelcase
            var reminder_flag = new Date().getTime() - new Date(this.orderInfo.pay_success_time.replace(/-/g, "/")).getTime();
            // eslint-disable-next-line camelcase
            if (reminder_flag < 48 * 60 * 60 * 1000) {
              showToast("支付48小时后才可催单");
            } else {
              const searchParams = {
                url: "/mall/order/reminder",
                method: "post",
                props: {
                  user_id: this.userId,
                  order_id: orderId
                }
              };
              postOrder(searchParams.url, searchParams.props).then(() => {
                showToast("已提醒商家优先为你发货");
              });
            }
          } else {
            showToast("已提醒商家优先为你发货");
          }
          break;
        case "cancel_refund":
          // 撤销退款
          showConfirmDialog({
            message: "确定要撤销本次退款申请吗？"
          }).then(() => {
            var searchParams = {
              url: "/mall/order/refund/cancel",
              method: "post",
              props: {
                refund_id: that.orderInfo.refund_id
              }
            };
            postOrder(searchParams.url, searchParams.props).then(res => {
              if (res) {
                showToast("操作成功");
                this.$router.go(0);
              }
            });
          });
          break;
        case "update_refund":
          // 更新退款 webview
          // 跳转退款页面
          lowerdev.openUrl("refund", {
            rid: this.orderInfo.refund_id
          });
          break;
        case "apply_deliver":
          //抽卡机发货按钮
          window.location.href = `${domain.wap}/luckycards/container`;
          break;
        case "look_card_goods":
          //抽卡机查看发货单
          window.location.href = `${domain.wap}/luckycards/container_order_list`;
          break;
        default:
          return false;
      }
    },
    async toPay() {
      let option = {
        type: 3,
        // 支付类型 1、众筹项目id 2、电商spuid 3、订单id
        id: this.orderId // 统一数据id，根据类型判断，有多个逗号分隔
      };
      const {
        status,
        data,
        message
      } = await getPayMethodList(option);
      if (status == 0) {
        if (data) {
          this.methodsList = data;
          const payMethod = data.find(obj => obj.is_default === 1) || data[0];
          this.pay_resource = payMethod.pay_type;
          // 仅有一种支付方式则直接调起支付
          if (data.length == 1) {
            this.payClick(payMethod.pay_type);
          } else {
            if (this.orderInfo.shop.skus && this.orderInfo.shop.skus.length) {
              this.orderInfo.apply_pay_amount = this.orderInfo.shop.skus[0].total_amount;
            }
            this.payShow = true;
          }
        } else {
          showToast('此订单不支持当前支付方式');
        }
      } else {
        showToast(message);
      }
    },
    orderDetailCallback(fn) {
      if (this.orderInfo.shop.skus.length === 1) {
        fn();
      } else {
        window.location.href = `${domain.wap}/product/evaluate/list?order_id=${this.orderInfo.order_id}`;
      }
    },
    ifAddress() {
      get("/apis/address/my_address_count", {
        user_id: this.userId
      }).then(({
        data
      }) => {
        if (data > 1) {
          this.dialog_address = true;
        } else {
          this.$router.push(`/address/add_address?redirectUrl=${encodeURIComponent(location.href)}&orderId=${this.orderId}&orderType=shop`);
        }
      });
    },
    toAddress(e) {
      if (e === 0) {
        this.$router.push(`/address/add_address?redirectUrl=${encodeURIComponent(location.href)}&orderId=${this.orderId}&orderType=shop`);
      } else {
        this.$router.push(`/address/address_list?redirectUrl=${encodeURIComponent(location.href)}&orderId=${this.orderId}&orderType=shop`);
      }
    },
    refundFn(e) {
      const orderId = this.orderId;
      lowerdev.confirm({
        msg: "确定要申请退款吗？"
      }, () => {
        let searchParams = {
          url: "/apis/mdorder/refund/create",
          method: "post",
          props: {
            order_id: orderId,
            refund_reason: e
          }
        };
        postOrder(searchParams.url, searchParams.props).then(res => {
          if (res) {
            window.location.reload();
            showToast("操作成功");
          }
        });
      });
    },
    callWeixinPay(json) {
      const loadStatusData = setInterval(() => {
        // eslint-disable-next-line no-undef
        if (WeixinJSBridge !== undefined) {
          clearInterval(loadStatusData);
          // eslint-disable-next-line no-undef
          WeixinJSBridge.invoke("getBrandWCPayRequest", json, res => {
            // eslint-disable-next-line no-undef
            WeixinJSBridge.log(res.err_msg);
            if (res.err_msg === "get_brand_wcpay_request:ok") {
              this.PaySuccessBack();
            } else if (res.err_msg === "get_brand_wcpay_request:cancel") {
              showToast("取消支付");
            } else {
              showToast(res.err_msg);
            }
          });
        }
      }, 300);
    },
    PaySuccessBack() {
      window.location.href = `${domain.wap}/product/payment/success?pay_id=${this.pay_id}`;
    },
    payClick(pay_resource) {
      sessionStorage.setItem('FailNext', 'reload');
      const orderId = this.orderId;
      let obj = {
        pay_resource: pay_resource
      };
      this.payPay.toPay({
        params: obj,
        type: 'mall',
        order_id: orderId
      });
    },
    addWxGroup() {
      const that = this;
      // eslint-disable-next-line no-undef
      sensors.track("MPClick", {
        page_source: "电商订单页",
        element_content: "点击添加"
      }, function () {
        // eslint-disable-next-line no-undef
        wx.miniProgram.navigateTo({
          url: "/pages/commonPages/contact/contact?order_id=" + that.orderInfo.order_id
        });
      });
    },
    show_dialog(e) {
      this.dialog_btn = JSON.parse(JSON.stringify(e));
      this.dialog_show = true;
    },
    close_dialog() {
      this.dialog_show = false;
    },
    share() {
      var target = this.$refs.copy;
      this.copyboard = new ClipboardJS(target, {
        text(target) {
          return target.getAttribute("copyData");
        }
      });
      this.copyboard.on("success", () => {
        // eslint-disable-next-line
        showSuccessToast("复制成功");
      });
    },
    dropBack() {
      if (history.length > 1) {
        this.$router.back(-1);
      } else {
        lowerdev.openUrl();
      }
    }
  }
};