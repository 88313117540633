import MDRouter from "@/service/router";
import { domClientWidth, domPixelRatio, env } from "md-base-tools/env";
import domain from "@/service/domain";
export default {
  name: "goodsInfo",
  props: {
    orderInfo: Object
  },
  data() {
    return {
      env,
      display_scale: "1x1"
    };
  },
  computed: {
    imgWidth() {
      return Math.round(domClientWidth() * domPixelRatio());
    }
  },
  components: {},
  mounted() {},
  methods: {
    openboxmore() {
      this.$emit("openboxmore");
    },
    //商品操作按钮
    // eslint-disable-next-line no-unused-vars
    op(type, oid, refund_id) {
      switch (type) {
        case "look_logistics":
        case "refunding":
          location.href = `${domain.wap}/product/refund/${refund_id}`;
          break;
        // 申请退款
        case "apply_refund":
          location.href = domain.wap + "/product/refund?soid=" + oid;
          break;
        // 申请售后
        case "apply_after":
          location.href = domain.wap + "/product/refund/apply?soid=" + oid;
          break;
        case "refund_success":
          break;
        default:
          location.href = `${domain.wap}/product/refund?refund=${refund_id}&rid=${refund_id}`;
      }
    },
    goShop() {
      location.href = `${domain.wap}/product/pop/${this.orderInfo.shop.shop_id}.html`;
    },
    goGoods(id) {
      if (this.orderInfo.extract_card_type && this.orderInfo.extract_card_type > 0) {
        MDRouter.goLuckycardsInfo(this.orderInfo.extract_card_id);
        return false;
      } else {
        MDRouter.goMallInfo(id);
      }
    },
    //客服按钮
    downApp() {}
  }
};